<template>
  <div>
    <BlockUI
      v-show="$store.state.showBlock"
      message="Vui lòng chờ trong giây lát"
    >
      <sweetalert-icon
        icon="loading"
        size="12"
      />
    </BlockUI>
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header>
        <b-card-title>Lịch sử giao dịch</b-card-title>
        <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
          dropright
          text="Chức năng"
          :right="$store.state.appConfig.isRTL"
        >
          <b-dropdown-item>
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            <span class="align-middle ml-50">Tìm kiếm</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-card-header>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        empty-text="Không có dữ liệu"
        show-empty
        responsive="sm"
        stacked="md"
        :items="fetchUsers"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        primary-key="id"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- A virtual column -->
        <template #cell(id)="data">
          <p class="text-right">
            {{ data.index+1 }}
          </p>
        </template>

        <!--        loai_giao_dich-->
        <template #cell(loai_giao_dich)="data">
          {{ (data.item.ghi_chu !== '' && data.item.ghi_chu !== null) ? 'Bán gói' : data.item.loai_giao_dich }}
        </template>

        <!--        so_luot_xem-->
        <template #cell(so_luot_xem)="data">
          <b-badge
            :variant="getClassSoLuotXem(data.item).class"
            class="badge-glow"
          >
            {{ getClassSoLuotXem(data.item).soLuotXem }}
          </b-badge>
        </template>

        <!--        nguoi_tao-->
        <template #cell(nguoi_tao)="data">
          <div>
            {{ data.item.nguoi_tao }} <feather-icon
              icon="ArrowRightCircleIcon"
              size="20"
              class="text-success"
            /> {{ data.item.khach_hang }}
          </div>
        </template>

        <!--        ngay_giao_dich-->
        <template #cell(ngay_giao_dich)="data">
          {{ data.item.ngay_giao_dich }}
          <div>
            <small class="text-muted">
              <feather-icon icon="CalendarIcon" /> {{ data.item.created }}</small>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Dòng {{ dataMeta.from }} đến {{ dataMeta.to }} / {{ dataMeta.of }} bản ghi</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BDropdown, BDropdownItem,
  BCardTitle, BBadge,
  BCardHeader,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import useGiaoDichList from './useGiaoDichList'
import giaoDichStoreModule from './giaoDichStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCardTitle,
    BBadge,
    BCardHeader,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-giao-dich'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, giaoDichStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useGiaoDichList()

    return {
      // Sidebar
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    getClassSoLuotXem(data) {
      const user = getUserData()
      if (user.id === data.user_id) {
        if (data.loai_giao_dich === 'Mua bài') {
          return {
            class: 'danger',
            soLuotXem: `- ${data.so_luot_xem}`,
          }
        }
        return {
          class: 'success',
          soLuotXem: `+ ${data.so_luot_xem}`,
        }
      }
      if (data.loai_giao_dich === 'Mua bài') {
        if (data.ghi_chu !== '' && data.ghi_chu !== null) {
          return {
            class: 'danger',
            soLuotXem: `- ${data.so_luot_xem}`,
          }
        }
        return {
          class: 'success',
          soLuotXem: `+ ${data.so_luot_xem}`,
        }
      }
      return {
        class: 'danger',
        soLuotXem: `- ${data.so_luot_xem}`,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
